const EMOJI_REGEX =
  /[\u{1F600}-\u{1F64F}|\u{1F300}-\u{1F5FF}|\u{1F680}-\u{1F6FF}|\u{2600}-\u{26FF}|\u{2700}-\u{27BF}|\u{1F1E6}-\u{1F1FF}|\u{1F900}-\u{1F9FF}]/gu;

export const cleanseStringValue = (value: string | null | undefined): string => {
  if (!value) return '';

  // Trim leading and trailing spaces
  let cleanValue = value.trim();

  // Regular expression to remove invalid characters at the start
  // ^[.,\s!&$#@*]+ matches any sequence of dots, commas, spaces, and other unwanted characters at the start
  cleanValue = cleanValue.replace(/^[.,\s!&$#@*]+/, '');

  // Regular expression to remove emoji characters (including skin tones and other variants)
  cleanValue = cleanValue.replace(EMOJI_REGEX, '');

  // If the remaining string is just invalid characters or numbers, return an empty string
  if (/^[.,\s!&$#@*]*$/.test(cleanValue) || /^[0-9]+$/.test(cleanValue)) {
    return '';
  }

  return cleanValue.trim();
};
