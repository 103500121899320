import { useEffect, useState } from 'react';

/**
 * @NOTE: Only pass a serialisable value. To deal with the fact that
 * non-primitive data types like arrays and objects can cause render loops, we
 * call JSON.stringify on the value. The enables react to correctly check
 * equality of the state value and only trigger a render when they are actually
 * different
 */
export const useDebounce = (value: any, delay: number) => {
  const [debouncedValue, setDebouncedValue] = useState(JSON.stringify(value));

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(JSON.stringify(value));
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue ? JSON.parse(debouncedValue) : debouncedValue;
};
