import { useRecoilState } from 'recoil';

import { placesProvider } from '@/lib/api/providers/fluent/places';
import { placeCacheState } from '@/state/places';
import { PlaceInterface } from '@/types/api-types';

export const usePlaceCache = () => {
  const [placeCache, setPlaceCache] = useRecoilState(placeCacheState);

  const getPlaceWithLocation = async ({ place }: { place?: PlaceInterface }): Promise<PlaceInterface | undefined> => {
    if (!place?.placeId) return undefined;
    if (place.location) {
      return place;
    }

    // Check the cache for the place before reaching out to the backend
    const cachedPlace = placeCache[place.placeId];
    if (cachedPlace) {
      return cachedPlace;
    }

    const placeWithLocation = await placesProvider.getDetails(place.placeId, place.name);
    // Store the place in a cache, so we don't fetch it again
    setPlaceCache((prev) => {
      return {
        ...prev,
        [placeWithLocation.placeId || 'unknown']: placeWithLocation,
      };
    });

    return placeWithLocation;
  };

  return { getPlaceWithLocation };
};
