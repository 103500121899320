import { useEffect } from 'react';
import { useSetRecoilState } from 'recoil';
import useSWRImmutable from 'swr/immutable';

import { useFeatureFlag } from '@/hooks/use-feature-flag';
import { AUTOCOMPLETE_TYPESENSE } from '@/lib/constants';
import { FEATURE_FLAGS } from '@/lib/constants/feature-flags';
import { isSearchingLocationAutocompleteState } from '@/state/search';
import { AutocompletePlaceTypes } from '@/types';
import { PlaceInterface } from '@/types/api-types';
import { AsyncDataInterface, LocationAutocompleteIdInterface } from '@/types/context-types';
import { request } from '@/utils/api/request';
import { createPlacesSearchUrl } from '@/utils/helpers';
import { cleanseStringValue } from '@/utils/helpers/cleanse-string-value';
import { logEvent } from '@/utils/logger';

interface UseLocationAutocompleteProps {
  name: LocationAutocompleteIdInterface;
  query?: string | null;
  placeTypes?: AutocompletePlaceTypes;
  shouldKeepPreviousData?: boolean;
}

interface UseLocationAutocompleteInterface extends AsyncDataInterface {
  suggestions: PlaceInterface[];
}

export const useLocationAutocomplete = ({
  name,
  query,
  placeTypes,
  shouldKeepPreviousData = true,
}: UseLocationAutocompleteProps): UseLocationAutocompleteInterface => {
  const setIsSearchingLocationAutocomplete = useSetRecoilState(isSearchingLocationAutocompleteState);

  const { variant: USE_TYPESENSE_AUTOCOMPLETE } = useFeatureFlag(FEATURE_FLAGS.TYPESENSE_AUTOCOMPLETE);
  const searchEngine = USE_TYPESENSE_AUTOCOMPLETE ? AUTOCOMPLETE_TYPESENSE : '';

  const cleansedQuery = cleanseStringValue(query);

  const { isLoading, error, data } = useSWRImmutable<{ results: PlaceInterface[] }>(
    cleansedQuery ? createPlacesSearchUrl(cleansedQuery, placeTypes, searchEngine) : null,
    request,
    {
      keepPreviousData: shouldKeepPreviousData,
      onError: (err) => {
        setIsSearchingLocationAutocomplete(null);
        logEvent.error('Autocomplete Response Error', err);
      },
    },
  );

  useEffect(() => {
    if (isLoading) {
      setIsSearchingLocationAutocomplete(name);
    } else {
      setIsSearchingLocationAutocomplete(null);
    }
  }, [isLoading, name]);

  return {
    suggestions: data?.results || [],
    error,
    isLoading,
  };
};
