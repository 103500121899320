import { PlaceInterface } from '@/types/api-types';
import { request } from '@/utils/api/request';
import { createPlaceDetailsSearchUrl } from '@/utils/helpers';

interface Places {
  getDetails: (id: string, name: string) => Promise<PlaceInterface>;
}

export const placesProvider: Places = {
  getDetails: async (id: string, name: string) => {
    const response = await request<{ place: PlaceInterface }>(createPlaceDetailsSearchUrl(id, name));

    return {
      ...response.place,
      name,
    };
  },
};
